
const mapAndSort = (organizations) => {
    const mapped = organizations.map((o) => {
        //TO DO: dataSourceType can be removed when the legacy APIs that use it are replaced
        return {
            ...o,
            label: o.guid ? `${o.name} (${o.guid})` : `${o.name} (${o.organizationID})`,
            value: o.organizationID,
            dataSourceType: o.dataSourceType
        };
    });

    return mapped.sort((a, b) =>
        (a.label || '').toLowerCase().localeCompare(b.label.toLowerCase())
    );
};

export const mapToFormattedOptions = (rawOptions) => {
    return mapAndSort(rawOptions);
};

export const mapFromFormattedSelectedOption = (selectedOption) => {
    return {
        organizationID: selectedOption.organizationID,
        name: selectedOption.name,
        guid: selectedOption.guid,
        dataSourceType: selectedOption.dataSourceType,
        externalId: selectedOption.externalId,
    };
};

export const mapToFormattedSelectedOptions = (selectedOption) => {
    return selectedOption &&
    {
        label: selectedOption.guid ? `${selectedOption.name} (${selectedOption.guid})` : `${selectedOption.name} (${selectedOption.organizationID})`,
        value: selectedOption.organizationID,
        dataSourceType: selectedOption.dataSourceType
    };
};
