import { actionTypes } from './constants';


const initialState = {
    maintenanceSchedule: {
        data: null,
        error: null,
        loadingStatus: null
    },
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_MAINTENANCE_SCHEDULE:
        case actionTypes.ADD_MAINTENANCE_SCHEDULE:
            return {
                ...state,
                maintenanceSchedule: { ...state.maintenanceSchedule, ...action },
            };
        default:
            return state;
    }
}

