import { reducer as PowertrainConfigurationReducer } from './PowertrainConfiguration/PowertrainConfigurationReducer';
import { reducer as SubscriptionStatusReducer } from './SubscriptionStatus/SubscriptionStatusReducer';
import { reducer as PowertrainWizardReducer } from './PowertrainConfiguration/PowertrainWizard/PowertrainWizardReducer';
import { reducer as GatewayManagementReducer } from './GatewayManagement/GatewayManagementReducer';
import { combineReducers } from 'redux';

export const reducer = combineReducers({
    configuration: PowertrainConfigurationReducer,
    subscriptionStatus: SubscriptionStatusReducer,
    wizard: PowertrainWizardReducer,
    gatewayManagement: GatewayManagementReducer,
});
