export const routes = {
    ConditionMonitoring: '/conditionmonitor',
    Overview: '/conditionmonitor/overview',
    PowertrainAssetsOverview: '/conditionmonitor/powertrain-assets-overview',
    ConditionOverview: '/conditionmonitor/condition-overview',
    MaintenanceOverview: '/conditionmonitor/maintenance-overview',
    Map: '/conditionmonitor/map',
    Cbm: '/conditionmonitor/cbm',
    PowertrainAndAssetDetails: '/pt-asset-details',
    DetailedInformation: '/pt-asset-details/detailed-information',
    AssetDetails: '/pt-asset-details/detailed-information/asset-details',
    OperationalParameters: '/pt-asset-details/detailed-information/operational-parameters',
    ConditionInsights: '/pt-asset-details/detailed-information/condition-insights',
    OperationalParametersV2: '/pt-asset-details/detailed-information/operational-parameters-v2',
    LimitConfig: '/pt-asset-details/detailed-information/asset-limit-config',
    // LimitConfigV2: '/pt-asset-details/detailed-information/asset-limit-configV2',
    EventLog: '/pt-asset-details/detailed-information/event-log',
    LimitsConfiguration: '/pt-asset-details/detailed-information/limits-configuration',
    SensorProperties: '/pt-asset-details/detailed-information/sensor-properties',
    AdvancedAnalytics: '/pt-asset-details/detailed-information/advanced-analytics',
    CrossKpi: '/pt-asset-details/cross-asset-analysis',
    ConditionBasedMaintenance: '/pt-asset-details/condition-based-maintenance',
    Tools: '/tools',
    ToolsPowertrainConfiguration: '/tools/powertrain-configuration',
    ToolsSubscriptionStatus: '/tools/subscription-status',
    ToolsGatewayManagement: '/tools/gateway-management',
    ToolsReportManagement: '/tools/report-management',
    ToolsEventLog: '/tools/event-log',
    ToolsSymptomMonitoring: '/tools/symptom-monitoring',
    User: '/user',
    UserProfile: '/user/profile',
    UserUnits: '/user/units',
    NotificationSettings: '/user/notification-settings',
    Reports: '/reports',
};

export const defaultLandingPage = routes.ConditionMonitoring;
