export const getShortDayOfWeek = (fullDayOfWeek) => {
    switch (fullDayOfWeek?.toLowerCase()) {
        case 'monday':
            return 'Mon';
        case 'tuesday':
            return 'Tue';
        case 'wednesday':
            return 'Wed';
        case 'thursday':
            return 'Thu';
        case 'friday':
            return 'Fri';
        case 'saturday':
            return 'Sat';
        case 'sunday':
            return 'Sun';
        default:
            return '';
    }
};

export const getFullDayOfWeek = (shortDayOfWeek) => {
    switch (shortDayOfWeek?.toLowerCase()) {
        case 'mon':
            return 'Monday';
        case 'tue':
            return 'Tuesday';
        case 'wed':
            return 'Wednesday';
        case 'thu':
            return 'Thurday';
        case 'fri':
            return 'Friday';
        case 'sat':
            return 'Saturday';
        case 'sun':
            return 'Sunday';
        default:
            return '';
    }
};

export const get12TimeFormat = (dateTime) => {
    let resultFormat = '00:00:00';
    if (dateTime) {
        const dataArray = dateTime.split(':');
        const hours = parseInt(dataArray[0]);
        if (hours >= 12) {
            resultFormat = hours - 12 + ':' + dataArray[1] + ':' + dataArray[2];
        }
        else {
            resultFormat = dateTime;
        }
    }
    return resultFormat;
};

export const get24TimeFormat = (dateTime, period) => {
    let resultFormat = '00:00:00';
    if (dateTime && period) {
        if (period.toLowerCase() === 'pm') {
            const dataArray = dateTime.split(':');
            const hours = parseInt(dataArray[0]);
            resultFormat = hours + 12 + ':' + dataArray[1] + ':' + dataArray[2];
        }
        else {
            resultFormat = dateTime;
        }
    }
    return resultFormat;
};

export const getDayPeriod = (dateTime) => {
    let resultFormat = 'AM';
    if (dateTime) {
        const dataArray = dateTime.split(':');
        const hours = parseInt(dataArray[0]);
        if (hours >= 12) {
            resultFormat = 'PM';
        }
    }
    return resultFormat;
};
