import { OrganizationManagementApi } from 'api';
import {
	apiCallStatus
} from 'helpers/constants';
import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getMaintenanceScheduleAction = getApiActionCreator(actionTypes.GET_MAINTENANCE_SCHEDULE);
export const addMaintenanceScheduleAction = getApiActionCreator(actionTypes.ADD_MAINTENANCE_SCHEDULE);

export const getMaintenanceSchedule = (externalId) => {
	if (externalId) {
		return (dispatch) => {
			dispatch(getMaintenanceScheduleAction(apiCallStatus.LOADING));
			return OrganizationManagementApi.getMaintenanceSchedule(externalId)
				.then((data) => {
					if (data && data.length > 0) {
						dispatch(getMaintenanceScheduleAction(apiCallStatus.SUCCESS, data[0]));
					}
				}, (err) => {
					dispatch(getMaintenanceScheduleAction(apiCallStatus.ERROR, null, err));
				});
		};
	}
};

export const addMaintenanceSchedule = (externalId, scheduleData) => {
	console.log('addMaintenanceSchedule', addMaintenanceSchedule);
	return (dispatch) => {
		dispatch(addMaintenanceScheduleAction(apiCallStatus.LOADING));

		return OrganizationManagementApi.addMaintenanceSchedule(externalId, scheduleData)
			.then((res) => {
				if (res === 200) {
					dispatch(addMaintenanceScheduleAction(apiCallStatus.SUCCESS, scheduleData));
				}
				else {
					dispatch(addMaintenanceScheduleAction(apiCallStatus.ERROR, null, res));
				}
			});
	};
};
