import { routes } from 'routes';

export const getTabOptions = (translate, isAdmin) => {
    const tabsOptions = {
        powertrainConfiguration: {
            title: translate('ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab'),
            route: routes.ToolsPowertrainConfiguration
        },
        subscriptionStatus: {
            title: translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatusTab'),
            route: routes.ToolsSubscriptionStatus,
        }
    };
    if (isAdmin) {
        tabsOptions.gatewayManagement = {
            title: translate('ABB.Powertrain.Frontend.ptToolsGatewayManagementTab'),
            route: routes.ToolsGatewayManagement,
        };
    }
    return tabsOptions;
};
