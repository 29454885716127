import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DisclaimerBanner,
    InfoBanner,
    TimeInput
} from 'sharedComponents';
import {
    get12TimeFormat,
    get24TimeFormat,
    getDayPeriod,
    getFullDayOfWeek,
    getShortDayOfWeek,
} from './helpers';

const GatewayManagementComponent = (
    {
        actions,
        model
    }
) => {
    const { externalId, maintenanceSchedule = {} } = model || {};
    const { getMaintenanceSchedule, addMaintenanceSchedule } = actions || {};
    const { t: translate } = useTranslation();
    const [selectedDay, setSelectedDay] = useState(getShortDayOfWeek(maintenanceSchedule?.data?.startDayOfWeek ?? 'Monday'));
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const [startTime, setStartTime] = useState(get12TimeFormat(maintenanceSchedule?.data?.startTime ?? '09:00:00'));
    const [startPeriod, setStartPeriod] = useState(getDayPeriod(maintenanceSchedule?.data?.startTime ?? '09:00:00'));
    const [endTime, setEndTime] = useState(get12TimeFormat(maintenanceSchedule?.data?.endTime ?? '20:00:00'));
    const [endPeriod, setEndPeriod] = useState(getDayPeriod(maintenanceSchedule?.data?.endTime ?? '20:00:00'));
    const [isStartTimeError, setIsStartTimeError] = useState(false);
    const [isEndTimeError, setIsEndTimeError] = useState(false);
    const [showButtons, setShowButtons] = useState(true);

    useEffect(() => {
        if (externalId) {
            getMaintenanceSchedule(externalId);
        }
    }, [externalId]);
    const handleDayClick = (day) => {
        setSelectedDay(day);
    };
    const handleResetClick = () => {
        setSelectedDay(getShortDayOfWeek(maintenanceSchedule?.data?.startDayOfWeek));
        setStartTime(get12TimeFormat(maintenanceSchedule?.data?.startTime));
        setStartPeriod(getDayPeriod(maintenanceSchedule?.data?.startTime));
        setEndTime(get12TimeFormat(maintenanceSchedule?.data?.endTime));
        setEndPeriod(getDayPeriod(maintenanceSchedule?.data?.endTime));
        setIsStartTimeError(false);
        setIsEndTimeError(false);
        setShowButtons(false);
    };
    const handleSaveClick = () => {
        if (!addMaintenanceSchedule || !externalId) {
            return;
        }
        else if (isStartTimeError) {
            setIsStartTimeError(false);
        }
        if (endTime === '' && !isEndTimeError) {
            setIsEndTimeError(true);
        }
        else if (isEndTimeError) {
            setIsEndTimeError(false);
        }
        if (!isStartTimeError && !isEndTimeError) {
            const maintenanceType = maintenanceSchedule?.data?.maintenanceType ?? 'Undefined';
            addMaintenanceSchedule(externalId, {
                'maintenanceType': maintenanceType,
                'startDayOfWeek': getFullDayOfWeek(selectedDay),
                'startTime': get24TimeFormat(startTime, startPeriod),
                'endDayOfWeek': getFullDayOfWeek(selectedDay),
                'endTime': get24TimeFormat(endTime, endPeriod),
            });
        }
    };
    return (
        <div className='tools_powertrain-gateway_management'>
            <div className='title'>
                <div className='title-box'>
                    {translate('ABB.Powertrain.Frontend.ptToolsGatewayManagement')}
                </div>
            </div>
            <div className='disclaimer-container'>
                <DisclaimerBanner text={translate('ABB.Powertrain.Frontend.disclaimerText')}
                    disableClose={true} />
                <div className='info-icon-wrapper'>
                    <InfoBanner text={translate('ABB.Powertrain.Frontend.smartSensorInfoText')} />
                </div>
            </div>
            <div className='day-of-week-container'>
                <label className='label-title'>{translate('ABB.Powertrain.Frontend.gatewayManagementDaysTitle')}</label>
                <div className='label-box'>
                    {days.map(day =>
                        <button
                            key={day}
                            className={`day ${selectedDay === day ? 'selected' : 'unselected'}`}
                            onClick={() => handleDayClick(day)}
                        >
                            {day}
                        </button>
                    )}
                </div>
            </div>

            <div className='timelabel'>
                <TimeInput label={translate('ABB.Powertrain.Frontend.gatewayManagementStartTime')} defaultTime={startTime} defaultPeriod={startPeriod} setTime={setStartTime} setPeriod={setStartPeriod} className={isStartTimeError ? 'input-time error ' : 'input-time'} errorMessage={isStartTimeError ? `${translate('ABB.Powertrain.Frontend.errorText')}` : null} />
                <TimeInput label={translate('ABB.Powertrain.Frontend.gatewayManagementEndTime')} defaultTime={endTime} defaultPeriod={endPeriod} setTime={setEndTime} setPeriod={setEndPeriod} className={isEndTimeError ? 'input-time error ' : 'input-time'} errorMessage={isEndTimeError ? `${translate('ABB.Powertrain.Frontend.errorText')}` : null} />
            </div>
            {showButtons &&
                <div className='buttons'>
                    <button className='reset' onClick={handleResetClick}>{translate('ABB.Powertrain.Frontend.resetButton')}</button>
                    <button className='save' onClick={handleSaveClick}>{translate('ABB.Powertrain.Frontend.saveButton')}</button>
                </div>
            }
        </div>
    );
};

export default GatewayManagementComponent;
