import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';
import sortBy from 'lodash/sortBy';
import { dataSourceTypes } from 'helpers/constants';
import { RestManagerWithSmartSensorUrl } from 'api/RestManager/RestManager';
class OrganizationManagement {
    getUserOrganizations(params) {

        const endpoint = formatEndpoint(endpoints.organizationManagement.getUserOrganizations);

        return RestManagerWithoutBaseUrl.post(endpoint, params)
            .then((organizations) => {
                const updatedOrganizations = organizations.map((org) => ({
                    ...org,
                    dataSourceType: dataSourceTypes[org.source],
                    organizationID: org.organizationId
                }));
                return sortBy(updatedOrganizations, (org) => org.name);
            });
    }

    getOrganizationByAsset(filter) {
        const endpoint = formatEndpoint(endpoints.organizationManagement.getOrganizationByAsset, { filter });
        return RestManagerWithoutBaseUrl.get(endpoint)
            .then((organization) => {
                if (organization) {
                    organization['organizationID'] = organization.organizationId;
                }
                return organization;
            });
    }

    getMaintenanceSchedule(id) {
        const endpoint = formatEndpoint(endpoints.organizationManagement.getMaintenanceSchedule, { id });
        return RestManagerWithSmartSensorUrl.get(endpoint);
    }

    addMaintenanceSchedule(id, scheduleData) {
		const endpoint = formatEndpoint(endpoints.organizationManagement.addMaintenanceSchedule, { id });
		return RestManagerWithSmartSensorUrl.post(endpoint, scheduleData);
	}
}

export default new OrganizationManagement();
