import { createSelector } from 'reselect';
import { filter } from 'lodash';
import { getEnvironmentStage } from './environmentHelper';
import { generalFilterAssets, generalFilterPowertrains } from './filterHelper';

export const getLoadingStatuses = createSelector([
    ({ generalFilter }) => [
        generalFilter.organizations.loadingStatus,
        generalFilter.sites.loadingStatus,
        generalFilter.assetGroups.loadingStatus,
        generalFilter.contextLoadingStatus
    ]
], (
    generalFilterLoadingStatus,
) => {
    return generalFilterLoadingStatus;
});

const getAllPowertrains = ({ overview }) => {
    return overview.powertrains.data || [];
};

export const getAllAssets = ({ overview }) => {
    return overview.assets.data || [];
};

export const getGeneralFilter = ({ generalFilter }) => {
    return generalFilter.selectedFilters;
};

export const getOrganizations = ({ generalFilter }) => {
    return generalFilter.organizations.data;
};

export const getGeneralFilteredAssets = createSelector([
    getAllAssets,
    getGeneralFilter
],
    (
        assets,
        generalFilter
    ) => generalFilterAssets(assets, generalFilter)
);

export const getMonitoredGeneralFilteredAssets = createSelector(
    [getGeneralFilteredAssets],
    (assets) => filter(assets, (asset) => asset.isMonitored)
);

export const getGeneralFilteredPowertrains = createSelector([
    getAllPowertrains,
    getGeneralFilter
],
    (
        powertrains,
        generalFilter
    ) => generalFilterPowertrains(powertrains, generalFilter).sort((a, b) => a.systemName.localeCompare(b.systemName))
);

export const getPowertrainLoadingStatus = ({ overview }) => {
    return overview.powertrains?.loadingStatus;
};

export const getAssetsLoadingStatus = ({ overview }) => {
    return overview.assets?.loadingStatus;
};

export const getContextLoadingStatus = ({ generalFilter }) => {
    return generalFilter?.contextLoadingStatus;
};

export const getEnvironmentStageSelector = ({ portal: { environmentProperties } }) => {
    return getEnvironmentStage(environmentProperties?.data?.environment);
};

export const getEnvironmentVariables = ({ portal }) => {
    return portal?.environmentProperties?.data;
};

export const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations?.length ?
        generalFilter?.selectedFilters?.organizations[0].organizationID : null;
};

export const getSelectedExternalId = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations?.length ?
        generalFilter?.selectedFilters?.organizations[0].externalId : null;
};
