import { createSelector } from 'reselect';
import { getSelectedExternalId } from 'helpers/selectors';

const getMaintananceSchedule = ({ powertrainTools }) => {
    return powertrainTools.gatewayManagement.maintenanceSchedule ?? null;
};

export const getModel = createSelector([
    getSelectedExternalId,
    getMaintananceSchedule,
],
    (
        externalId,
        maintenanceSchedule
    ) => {
        return {
            externalId,
            maintenanceSchedule
        };
    });
